<header>
  <span>WARNING</span>
  <oph-x-button (buttonClick)="onClose()"></oph-x-button>
</header>

<p>Are you sure you want to permanently delete the following {{ type | titlecase }}?</p>

@if (type === 'tracker') {
  <div class="display-container">
    <span class="name">{{ tracker?.name || '' }}</span>
    <span class="metric-count ml-15">{{ tracker?.metrics?.length || 0 }} Metrics</span>
  </div>
}

@if (type === 'metric') {
  <div class="display-container metric-display-container">
    <div class="display-container-row">
      <span class="name">{{ metric?.name || 'Untitled' }}</span>
    </div>
    <div class="display-container-row mw-360">
      <div class="display-item">
        <label>CURRENT</label>
        <span>{{ metric?.value || 'Undefined' }}</span>
      </div>
      <div class="display-item">
        <label>TYPE</label>
        <span>{{ metric?.type | trackerMetricTypeDisplay | titlecase }}</span>
      </div>
      <div class="display-item">
        <label>DEFAULT</label>
        <span>{{ metric?.defaultValue || 'Undefined' }}</span>
      </div>
    </div>
  </div>
}
@if (type === 'user') {
  <div class="display-container">
    <span class="name">{{ user?.username || 'Username not found' }}</span>
  </div>
}

<footer>
  <oph-button
    appearance="outline"
    color="gray"
    (buttonClick)="onClose()"
    [style]="{height: '36px', 'border-radius': '6px', padding: '0 16px'}"
    >Cancel</oph-button
  >
  <oph-button
    color="red"
    (click)="onClose(true)"
    [style]="{height: '36px', 'border-radius': '6px', padding: '0 16px', width: '110px'}"
    [loading]="loading"
    >Yes, Delete</oph-button
  >
</footer>
