@if (
  {
    currentSkedScreen: currentSkedScreen$ | async,
    activeUser: activeUser$ | async,
    url: url$ | async,
    scheduleSelected: scheduleSelected$ | async,
    userTeamName: userTeamName$ | async
  };
  as state
) {
  <div class="container default-theme">
    <div>
      <button mat-button disabled class="ophanim-button">
        <img src="assets/img/fullsked-logo.svg" />
      </button>
    </div>
    @if (state.url?.startsWith('/schedule') && !state.url?.startsWith('/scheduler')) {
      <scheduler-toolbar [activeUser]="state.activeUser"></scheduler-toolbar>
    }
    @if (state.url === '/project') {
      <project-viewer-top-bar></project-viewer-top-bar>
    }
    @if (state.url === '/scheduler') {
      <button class="schedule-menu-button" [matMenuTriggerFor]="scheduleMenu">
        <span>{{ state.scheduleSelected === false ? 'Personal' : state.userTeamName }}</span>
        <oph-icon name="caret-down-brown" size="10"></oph-icon>
      </button>
      <mat-menu #scheduleMenu="matMenu">
        <button mat-menu-item (click)="onSelectChange(false)" [class.selected]="state.scheduleSelected === false">
          Personal
        </button>
        @if (state.activeUser?.teams.length > 0) {
          <button mat-menu-item (click)="onSelectChange(true)" [class.selected]="state.scheduleSelected === true">
            {{ state.userTeamName }}
          </button>
        }
      </mat-menu>
    }
    <div class="right-container">
      @if (state.url === '/current-sked') {
        <current-sked-selector></current-sked-selector>
      }
      <span>{{ getTimeZone() }}</span>
      <user-menu [mobile]="false" class="user-menu-margin"></user-menu>
      <button class="menu-toggle-button" (click)="toggleMenu.emit()">
        <img src="assets/img/icons/menu.svg" />
      </button>
    </div>
  </div>
}
