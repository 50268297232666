import {createSelector} from '@ngrx/store';
import {AppState} from '../app-state';
import {selectRouterQueryParam} from '../router/router.selector';

export const selectTrackersState = (state: AppState) => state.trackers;

export const selectTrackersListParams = createSelector(
  selectRouterQueryParam('search'),
  selectRouterQueryParam('page'),
  selectRouterQueryParam('sortField'),
  (search, page, sortField) => ({
    search,
    page: page ? Number(page) : 1,
    sortField: sortField ? String(sortField) : '',
  })
);

export const selectTrackerData = createSelector(selectTrackersState, state => state.trackerData);

export const selectTrackers = createSelector(selectTrackersState, state => state.trackerData?.trackers);

export const selectTrackersMap = createSelector(
  selectTrackers,
  trackers =>
    trackers?.reduce((acc, tracker) => {
      acc[tracker._id] = tracker;
      return acc;
    }, {})
);

export const selectTrackerAutocompleteOptions = createSelector(selectTrackers, trackers => {
  return trackers?.reduce((reducedTrackers, tracker) => {
    if (tracker.metrics?.length) {
      reducedTrackers.push({
        name: tracker.name,
        _id: tracker._id,
      });
    }
    return reducedTrackers;
  }, []);
});

export const selectSingleTracker = createSelector(selectTrackersState, state => state.tracker);
