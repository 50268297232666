import {Action} from '@ngrx/store';
import {ListSearchParams} from '../../model/list-search-params';
import {Tracker, TrackerData, TrackerDetailData, TrackerMetric} from '../../model/tracker';
import {HttpErrorResponse} from '@angular/common/http';

export enum TrackersActionType {
  GET_ALL = '[Trackers] Get All',
  GET_ALL_SUCCESS = '[Trackers] Get All :: Success',

  GET_SINGLE = '[Trackers] Get Single',
  GET_SINGLE_SUCCESS = '[Trackers] Get Single :: Success',

  CREATE_NEW = '[Trackers] Create New',
  CREATE_NEW_SUCCESS = '[Trackers] Create New :: Success',

  UPDATE = '[Trackers] Update',
  UPDATE_SUCCESS = '[Trackers] Update :: Success',

  DUPLICATE = '[Trackers] Duplicate',
  DUPLICATE_SUCCESS = '[Trackers] Duplicate :: Success',

  DELETE = '[Trackers] Delete',

  CREATE_NEW_METRIC = '[Trackers] Create New Metric',
  CREATE_NEW_METRIC_SUCCESS = '[Trackers] Create New Metric :: Success',

  ADD_METRIC_TO_TRACKER = '[Trackers] Add Metric to Tracker',
  ADD_METRIC_TO_TRACKER_SUCCESS = '[Trackers] Add Metric to Tracker :: Success',

  UPDATE_METRIC = '[Trackers] Update Metric',
  UPDATE_METRIC_SUCCESS = '[Trackers] Update Metric :: Success',

  UPDATE_METRIC_VALUE = '[Trackers] Update Metric Value',
  UPDATE_METRIC_VALUE_SUCCESS = '[Trackers] Update Metric Value :: Success',

  DUPLICATE_METRIC = '[Trackers] Duplicate Metric',
  DUPLICATE_METRIC_SUCCESS = '[Trackers] Duplicate Metric :: Success',

  DELETE_METRIC = '[Trackers] Delete Metric',
  DELETE_METRIC_SUCCESS = '[Trackers] Delete Metric :: Success',
}

export class GetAllTrackersAction implements Action {
  public readonly type = TrackersActionType.GET_ALL;

  public constructor(
    public payload: {
      params: ListSearchParams;
      onSuccess?: (trackerData: TrackerData) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class GetAllTrackersSuccessAction implements Action {
  public readonly type = TrackersActionType.GET_ALL_SUCCESS;

  public constructor(public payload: {trackerData: TrackerData}) {}
}

export class GetSingleTrackerAction implements Action {
  public readonly type = TrackersActionType.GET_SINGLE;

  public constructor(
    public payload: {
      id: string;
      onSuccess?: (trackerDetailData: TrackerDetailData) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class GetSingleTrackerSuccessAction implements Action {
  public readonly type = TrackersActionType.GET_SINGLE_SUCCESS;

  public constructor(public payload: {trackerDetailData: TrackerDetailData}) {}
}

export class CreateNewTrackerAction implements Action {
  public readonly type = TrackersActionType.CREATE_NEW;

  public constructor(
    public payload: {
      onSuccess?: (tracker: Tracker) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class CreateNewTrackerSuccessAction implements Action {
  public readonly type = TrackersActionType.CREATE_NEW_SUCCESS;

  public constructor(public payload: {tracker: Tracker}) {}
}

export class UpdateTrackerAction implements Action {
  public readonly type = TrackersActionType.UPDATE;

  public constructor(
    public payload: {
      tracker: Tracker;
      onSuccess?: () => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class UpdateTrackerSuccessAction implements Action {
  public readonly type = TrackersActionType.UPDATE_SUCCESS;

  public constructor(public payload: {tracker: Tracker}) {}
}

export class DuplicateTrackerAction implements Action {
  public readonly type = TrackersActionType.DUPLICATE;

  public constructor(
    public payload: {
      trackerId: string;
      onSuccess?: (tracker: Tracker) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class DuplicateTrackerSuccessAction implements Action {
  public readonly type = TrackersActionType.DUPLICATE_SUCCESS;

  public constructor(public payload: {tracker: Tracker}) {}
}

export class DeleteTrackerAction implements Action {
  public readonly type = TrackersActionType.DELETE;

  public constructor(
    public payload: {
      trackerId: string;
      onSuccess?: () => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class CreateNewTrackerMetricAction implements Action {
  public readonly type = TrackersActionType.CREATE_NEW_METRIC;

  public constructor(
    public payload: {
      onSuccess?: (tracker: Tracker) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class CreateNewTrackerMetricSuccessAction implements Action {
  public readonly type = TrackersActionType.CREATE_NEW_METRIC_SUCCESS;

  public constructor(public payload: {tracker: Tracker}) {}
}

export class AddMetricToTrackerAction implements Action {
  public readonly type = TrackersActionType.ADD_METRIC_TO_TRACKER;

  public constructor(
    public payload: {
      trackerId: string;
      metric: TrackerMetric;
      onSuccess?: (tracker: Tracker) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class AddMetricToTrackerSuccessAction implements Action {
  public readonly type = TrackersActionType.ADD_METRIC_TO_TRACKER_SUCCESS;

  public constructor(public payload: {tracker: Tracker}) {}
}

export class UpdateTrackerMetricAction implements Action {
  public readonly type = TrackersActionType.UPDATE_METRIC;

  public constructor(
    public payload: {
      trackerId: string;
      metric: TrackerMetric;
      onSuccess?: (tracker: Tracker) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class UpdateTrackerMetricSuccessAction implements Action {
  public readonly type = TrackersActionType.UPDATE_METRIC_SUCCESS;

  public constructor(public payload: {tracker: Tracker}) {}
}

export class UpdateTrackerMetricValueAction implements Action {
  public readonly type = TrackersActionType.UPDATE_METRIC_VALUE;

  public constructor(
    public payload: {
      trackerId: string;
      metricId: string;
      value: string;
      onSuccess?: (tracker: Tracker) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class UpdateTrackerMetricValueSuccessAction implements Action {
  public readonly type = TrackersActionType.UPDATE_METRIC_VALUE_SUCCESS;

  public constructor(public payload: {tracker: Tracker}) {}
}

export class DuplicateTrackerMetricAction implements Action {
  public readonly type = TrackersActionType.DUPLICATE_METRIC;

  public constructor(
    public payload: {
      trackerId: string;
      metricId: string;
      onSuccess?: (tracker: Tracker) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class DuplicateTrackerMetricSuccessAction implements Action {
  public readonly type = TrackersActionType.DUPLICATE_METRIC_SUCCESS;
}

export class DeleteTrackerMetricAction implements Action {
  public readonly type = TrackersActionType.DELETE_METRIC;

  public constructor(
    public payload: {
      trackerId: string;
      metricId: string;
      onSuccess?: (tracker: Tracker) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class DeleteTrackerMetricSuccessAction implements Action {
  public readonly type = TrackersActionType.DELETE_METRIC_SUCCESS;
}

export type TrackersAction =
  | GetAllTrackersAction
  | GetAllTrackersSuccessAction
  | GetSingleTrackerAction
  | GetSingleTrackerSuccessAction
  | CreateNewTrackerAction
  | CreateNewTrackerSuccessAction
  | UpdateTrackerAction
  | UpdateTrackerSuccessAction
  | DuplicateTrackerAction
  | DuplicateTrackerSuccessAction
  | DeleteTrackerAction
  | CreateNewTrackerMetricAction
  | CreateNewTrackerMetricSuccessAction
  | AddMetricToTrackerAction
  | AddMetricToTrackerSuccessAction
  | UpdateTrackerMetricAction
  | UpdateTrackerMetricSuccessAction
  | UpdateTrackerMetricValueAction
  | UpdateTrackerMetricValueSuccessAction
  | DuplicateTrackerMetricAction
  | DuplicateTrackerMetricSuccessAction
  | DeleteTrackerMetricAction
  | DeleteTrackerMetricSuccessAction;
