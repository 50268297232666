import {createEntityAdapter} from '@ngrx/entity';
import {Project, ProjectViewerProject} from '../../../project-builder/shared/model/project';
import {TaskTemplate} from '../../model/task';

export interface ProjectBuilderState {
  currentProject: Project;
  hasUnsavedChanges: boolean;
  saveInProgress: boolean;
  projectTemplates: Project[];
  tabIndex: number;
  taskTemplates: TaskTemplate[];
  type: 'horizontal' | 'vertical';
  projectViewerProject: ProjectViewerProject;
  projectViewerTaskId: string;
}

export const projectBuilderAdapter = createEntityAdapter<ProjectBuilderState>();

export const initialProjectBuilderState: ProjectBuilderState = projectBuilderAdapter.getInitialState({
  currentProject: null, // dummySceneTemplates[0],
  hasUnsavedChanges: false,
  saveInProgress: false,
  projectTemplates: [],
  taskTemplates: [],
  tabIndex: 1,
  type: 'horizontal',
  projectViewerProject: null,
  projectViewerTaskId: null,
});
