import {ActionsState, initialActionsState} from './actions/actions.state';
import {ActiveUserState, initialActiveUserState} from './active-user/active-user.state';
import {CurrentSkedState, initialCurrentSkedState} from './current-sked/current-sked.state';
import {GoalsState, initialGoalsState} from './goals/goals.state';
import {initialLensesState, LensesState} from './lenses/lenses.state';
import {initialLocationsState, LocationsState} from './locations/locations.state';
import {initialPrivilegesState, PrivilegesState} from './privileges/privileges.state';
import {initialProgramsState, ProgramsState} from './programs/programs.state';
import {initialProjectBuilderState, ProjectBuilderState} from './project-builder/project-builder.state';
import {initialReportingState, ReportingState} from './reporting/reporting.state';
import {initialRolesState, RolesState} from './roles/roles.state';
import {CustomRouterReducerState} from './router/router.state';
import {initialScheduleState, ScheduleState} from './schedule/schedule.state';
import {initialSchedulerState, SchedulerState} from './scheduler/scheduler.state';
import {initialSkillsState, SkillsState} from './skills/skills.state';
import {initialTeamsState, TeamsState} from './teams/teams.state';
import {initialTrackersState, TrackersState} from './trackers/trackers.state';
import {initialUsersState, UsersState} from './users/users.state';

export interface AppState {
  activeUser: ActiveUserState;
  privileges: PrivilegesState;
  roles: RolesState;
  router: CustomRouterReducerState;
  projectBuilder: ProjectBuilderState;
  scheduler: SchedulerState;
  skills: SkillsState;
  users: UsersState;
  programs: ProgramsState;
  locations: LocationsState;
  actions: ActionsState;
  teams: TeamsState;
  reporting: ReportingState;
  currentSked: CurrentSkedState;
  lenses: LensesState;
  schedule: ScheduleState;
  goals: GoalsState;
  trackers: TrackersState;
}

export function initialAppState(): AppState {
  return {
    activeUser: initialActiveUserState,
    privileges: initialPrivilegesState,
    roles: initialRolesState,
    router: null,
    projectBuilder: initialProjectBuilderState,
    scheduler: initialSchedulerState,
    skills: initialSkillsState,
    users: initialUsersState,
    programs: initialProgramsState,
    locations: initialLocationsState,
    actions: initialActionsState,
    teams: initialTeamsState,
    reporting: initialReportingState,
    currentSked: initialCurrentSkedState,
    lenses: initialLensesState,
    schedule: initialScheduleState,
    goals: initialGoalsState,
    trackers: initialTrackersState,
  };
}
