@if ({showTopbar: showTopbar$ | async}; as state) {
  <mat-drawer-container class="drawer-container" [hasBackdrop]="true" mode="over">
    <mat-drawer #drawer>
      <!-- inside drawer -->
      <mobile-navbar
        [user]="activeUser$ | async"
        [routerUrl]="routerUrl$ | async"
        (close)="drawer.toggle()"
        (mobileNavItemClick)="drawer.toggle()"
        (logout)="onLogout()"
      ></mobile-navbar>
    </mat-drawer>
    <mat-drawer-content>
      <oph-top-bar (toggleMenu)="drawer.toggle()"></oph-top-bar>
      <div class="ng-content-container">
        <ng-content></ng-content>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
}
