import {AsyncPipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {OphLoadingModule} from '../oph-loading/oph-loading.module';

@Component({
  selector: 'oph-paginator',
  standalone: true,
  imports: [MatIconModule, AsyncPipe, OphLoadingModule],
  templateUrl: './oph-paginator.component.html',
  styleUrl: './oph-paginator.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OphPaginatorComponent implements OnChanges {
  @Input() page: number = 1;
  @Input() totalPages: number;

  loadingIndex$ = new BehaviorSubject<number>(-1);

  countArray: number[];

  constructor(private router: Router) {}

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.page || changes.totalPages) && this.page && this.totalPages) {
      this.countArray = Array.from({length: this.totalPages}, (_, i) => i + 1);
      this.loadingIndex$.next(-1);
    }
  }

  onBack() {
    if (this.page === 1) {
      return;
    }
    this.handlePage(this.page - 1);
  }

  onForward() {
    if (this.page === this.totalPages) {
      return;
    }

    this.handlePage(this.page + 1);
  }

  onPage(newPage: number) {
    if (this.page === newPage) {
      return;
    }
    this.handlePage(newPage);
  }

  handlePage(page: number) {
    this.loadingIndex$.next(page - 1);
    this.router.navigate([], {queryParams: {page}, queryParamsHandling: 'merge'});
  }
}
