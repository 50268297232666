import {Tracker, TrackerData, TrackerDetailData} from '../../model/tracker';

export interface TrackersState {
  trackerData: TrackerData;
  trackerDetailData: TrackerDetailData;
  tracker: Tracker;
}

export const initialTrackersState: TrackersState = {
  trackerData: null,
  trackerDetailData: null,
  tracker: null,
};
