import {TrackersAction, TrackersActionType} from './trackers.action';
import {initialTrackersState, TrackersState} from './trackers.state';

export function trackersReducer(state: TrackersState = initialTrackersState, action: TrackersAction): TrackersState {
  switch (action.type) {
    case TrackersActionType.GET_ALL_SUCCESS:
      return {...state, trackerData: action.payload.trackerData};
    case TrackersActionType.GET_SINGLE_SUCCESS:
      return {
        ...state,
        tracker: action.payload.trackerDetailData?.tracker,
        trackerDetailData: action.payload.trackerDetailData,
      };

    case TrackersActionType.CREATE_NEW_SUCCESS:
    case TrackersActionType.UPDATE_SUCCESS:
    case TrackersActionType.UPDATE_METRIC_VALUE_SUCCESS:
    case TrackersActionType.ADD_METRIC_TO_TRACKER_SUCCESS:
    case TrackersActionType.UPDATE_METRIC_SUCCESS:
      return {...state, tracker: action.payload.tracker};
    default:
      return state;
  }
}
