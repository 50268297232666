import {Pipe, PipeTransform} from '@angular/core';
import {Project} from 'src/app/project-builder/shared/model/project';

@Pipe({
  name: 'remainingProjectNames',
  standalone: true,
})
export class RemainingProjectNamesPipe implements PipeTransform {
  transform(projects: Project[], startIndex: number): string {
    return projects
      .slice(startIndex)
      .map(project => project.label)
      .join(', ');
  }
}
