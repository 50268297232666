import {SlicePipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {MatTooltip} from '@angular/material/tooltip';
import {Project} from 'src/app/project-builder/shared/model/project';
import {RemainingProjectNamesPipe} from 'src/app/shared/pipes/remaining-project-names.pipe';

@Component({
  selector: 'list-views-project-display',
  standalone: true,
  imports: [SlicePipe, MatTooltip, RemainingProjectNamesPipe],
  templateUrl: './list-views-project-display.component.html',
  styleUrl: './list-views-project-display.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListViewsProjectDisplayComponent {
  @Input() projects: Project[];
}
