import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Store, select} from '@ngrx/store';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {selectRouterUrl} from '../../../core/store/router/router.selector';

@Component({
  selector: 'desktop-layout',
  templateUrl: './desktop-layout.component.html',
  styleUrls: ['./desktop-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesktopLayoutComponent implements OnInit {
  public routerUrl$: Observable<string>;
  onCurrentSked$: Observable<boolean>;

  public username: string;

  constructor(private store$: Store<{}>) {}

  ngOnInit() {
    this.routerUrl$ = this.store$.pipe(select(selectRouterUrl));
    this.username = JSON.parse(localStorage.getItem('currentUser')).username;
    this.onCurrentSked$ = this.checkUrl();
  }

  isSchedulerWeekView() {
    return this.routerUrl$.pipe(map(url => url?.indexOf('/scheduler/week') === 0));
  }

  checkUrl() {
    return this.routerUrl$.pipe(map(url => url?.startsWith('/current-sked')));
  }
}
